<template>
  <Sidebar>
    <template #header>
      <div class="px-5">
        <Skeleton height="14px" width="160px" />
      </div>
    </template>

    <SidebarSection v-for="idx1 in 6" :key="idx1" :collapsable="true">
      <template #title>
        <div class="w-full">
          <Skeleton height="18px" width="130px" />
        </div>
      </template>
      <SidebarItem v-for="idx2 in 3" :key="idx2">
        <Skeleton height="18px" width="100px" />
      </SidebarItem>
    </SidebarSection>
  </Sidebar>
</template>
<script setup lang="ts"></script>
